import {
  IdentityPermissionRole,
  useCompanyRolesQuery,
} from 'generated/graphql';

export function useIsCompanySsoEnabled({ companyId }: { companyId: number }) {
  const { data } = useCompanyRolesQuery({
    variables: {
      companyId: companyId,
    },
  });

  const isSsoEnabled = Boolean(
    data?.CompanyRoles.some(
      companyRole =>
        companyRole.role.name ===
          IdentityPermissionRole.CompanyCandidate.toLowerCase() &&
        companyRole.samlAuthInfos.length > 0
    )
  );

  return isSsoEnabled;
}
