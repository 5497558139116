import clsx from 'clsx';
import ReportsAccess from 'components/feature/ReportsAccess/ReportsAccess';
import { useField, useFormikContext } from 'formik';
import { StageType } from 'generated/graphql';
import { FC, useEffect } from 'react';
import {
  FormikCheckboxFormField,
  FormikDatepicker,
  FormikMultiselectFormField,
  FormikSelectFormField,
  FormikTextInputField,
  TMultiselectOption,
} from '@spotted-zebra-uk/ui-components';
import { stageModeOptions, stageTypeOptions } from '../helpers';
import { StageModes } from '../types';
import { IStageFormValues } from './StageForm.interfaces';
import styles from './StageForm.module.scss';

const StageFormFields = ({
  projectRoles,
  isMultiMatch,
  reportsAccess,
}: {
  projectRoles: TMultiselectOption[];
  isMultiMatch: boolean;
  reportsAccess: boolean;
}) => {
  const { values, setFieldValue, setFieldError, errors } = useFormikContext<
    IStageFormValues
  >();

  useEffect(() => {
    // Its function of this useEffect is to monitor changes in the 'stageMode' field and clear any existing errors on 'stageProjectRoles' if the stage mode changes to a type that does not require project roles (e.g., any mode other than "Role Based" or "Role Based and Assignee Based").

    // This ensures that the stageProjectRoles dropdown does not display validation errors that are no longer relevant. For example:
    // - If the user initially selects a mode that requires project roles and does not select any roles, an error will be shown on submit ( "This is a required field.").
    // - If the user then changes the stage mode to one that does not require project roles, this component will clear the error automatically
    if (
      values.stageMode.value !== StageModes.PROJECT_ROLE_BASED &&
      values.stageMode.value !==
        StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED
    ) {
      setFieldError('stageProjectRoles', undefined);
    }
  }, [errors.stageProjectRoles, values.stageMode.value, setFieldError]);

  return (
    <div className={styles.form}>
      <div className={styles.section}>
        <p className={styles.sectionTitle}>General</p>

        <div className={styles.row}>
          <FormikTextInputField
            label="Name"
            id="stageName"
            placeholder="Stage name"
            className={styles.nameField}
            data-role="stage-name"
            useFormikField={useField}
          />
          <FormikSelectFormField
            label="Type"
            id="stageType"
            options={stageTypeOptions}
            placeholder="Type"
            className={styles.selectField}
            useFormikField={useField}
            hasClearIndicator={false}
          />
        </div>
        <div className={styles.row}>
          <FormikDatepicker
            id="stageStartTime"
            name="stageStartTime"
            label="Start at"
            placeholder="Start at"
            className={styles.startTimeDatepicker}
            hasTime
            useFormikField={useField}
          />
          <EndAtFormikDatepicker />
          <div className={styles.endTimeCheckbox}>
            <FormikCheckboxFormField
              id="stageIsEndTimeNull"
              name="stageIsEndTimeNull"
              label="No end time"
              useFormikField={useField}
            />
          </div>
        </div>
      </div>

      {values.stageType.value !== StageType.External ? (
        <>
          <div className={styles.checkboxesContainer}>
            <FormikCheckboxFormField
              id="stageEmailCandidateReport"
              label="Email candidate the report"
              data-role="stage-candidate-email-report-check"
              useFormikField={useField}
            />
            <FormikCheckboxFormField
              id="stageEmailSzAdmin"
              label="Email SZ admin the reports"
              data-role="stage-sz-admin-email-report-check"
              useFormikField={useField}
            />
            <FormikCheckboxFormField
              id="createInterviewGuide"
              label="Enable DIG"
              data-role="stage-enable-dig"
              useFormikField={useField}
            />
          </div>
        </>
      ) : null}

      <div className={styles.section}>
        <h3 className={styles.sectionTitle}>Project team emails</h3>
        {/* 
        The stageMode options "Access Based" and "Assignee Based" will leave the Project Roles dropdown as disabled. However, if "Project role based" or "Project role based & assignee based" are selected, the Project Role dropdown becomes enabled. If the Project role dropdown has an error and the user goes back to change the stage mode (to "Access Based" for example), then the Project Role error should be removed. Due to issues with Formik on the Admin app, this behavior cannot be set in the stage mode dropdown onChange
      */}
        <div className={clsx(styles.row, styles.small)}>
          <FormikSelectFormField
            id="stageMode"
            label="Mode"
            options={stageModeOptions}
            onChange={() => {
              setFieldValue('stageProjectRoles', []);
            }}
            useFormikField={useField}
            hasClearIndicator={false}
          />
        </div>
        <div className={styles.row}>
          <FormikMultiselectFormField
            id="stageProjectRoles"
            label="Project role(s)"
            placeholder="Project role(s)"
            isDisabled={
              values.stageMode.value === StageModes.PROJECT_ROLE_BASED ||
              values.stageMode.value ===
                StageModes.PROJECT_ROLE_BASED_AND_ASSIGNEE_BASED
                ? false
                : true
            }
            options={projectRoles}
            useFormikField={useField}
          />
        </div>
        <div className={clsx(styles.row, styles.reportsAccess)}>
          <ReportsAccess
            isMultiMatch={isMultiMatch}
            linksEnabled={reportsAccess}
            tooltipEnabled={false}
            onSubmit={(value: boolean) => {
              setFieldValue('managerReportEmailLinks', value);
            }}
            disabled={isMultiMatch}
          />
        </div>
      </div>
    </div>
  );
};

const EndAtFormikDatepicker: FC = () => {
  const { values, setFieldValue } = useFormikContext<IStageFormValues>();

  useEffect(() => {
    if (values.stageIsEndTimeNull) {
      setFieldValue('stageEndTime', '');
    }
  }, [values.stageIsEndTimeNull, setFieldValue]);

  return (
    <FormikDatepicker
      id="stageEndTime"
      name="stageEndTime"
      label="End at"
      placeholder="End at"
      className={styles.endTimeDatepicker}
      isDisabled={values.stageIsEndTimeNull}
      useFormikField={useField}
    />
  );
};

export default StageFormFields;
